exports.components = {
  "component---src-modules-ui-pages-publications-listing-wrapped-listing-tsx": () => import("./../../../src/modules/ui/pages/PublicationsListing/WrappedListing.tsx" /* webpackChunkName: "component---src-modules-ui-pages-publications-listing-wrapped-listing-tsx" */),
  "component---src-modules-ui-pages-single-publication-index-tsx": () => import("./../../../src/modules/ui/pages/SinglePublication/index.tsx" /* webpackChunkName: "component---src-modules-ui-pages-single-publication-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-index-tsx": () => import("./../../../src/pages/datenschutz/index.tsx" /* webpackChunkName: "component---src-pages-datenschutz-index-tsx" */),
  "component---src-pages-haftungsausschluss-index-tsx": () => import("./../../../src/pages/haftungsausschluss/index.tsx" /* webpackChunkName: "component---src-pages-haftungsausschluss-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-publikationen-index-tsx": () => import("./../../../src/pages/preview/publikationen/index.tsx" /* webpackChunkName: "component---src-pages-preview-publikationen-index-tsx" */),
  "component---src-pages-teilnehmen-index-tsx": () => import("./../../../src/pages/teilnehmen/index.tsx" /* webpackChunkName: "component---src-pages-teilnehmen-index-tsx" */),
  "component---src-pages-ueber-uns-index-tsx": () => import("./../../../src/pages/ueber-uns/index.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-index-tsx" */),
  "component---src-pages-was-wir-glauben-index-tsx": () => import("./../../../src/pages/was-wir-glauben/index.tsx" /* webpackChunkName: "component---src-pages-was-wir-glauben-index-tsx" */)
}

